import { BaseAPI } from "./BaseAPI";

class BillingAPI extends BaseAPI {
  constructor() {
    super("payment-setting");
  }

  get = async () => {
    return await this.service.get("/");
  };

  getBillingSettings = async () => {
    return await this.service.get("/stripe");
  };

  updatePublicKey = async (newKey) => {
    return await this.service.put("/stripe/public-key", { publicKey: newKey });
  };

  updateSecretKey = async (newKey) => {
    return await this.service.put("/stripe/secret-key", { secretKey: newKey });
  };

  updateTrialLength = async (trialDays, priceId) => {
    return await this.service.put("/stripe/trial-days", { trialDays, priceId });
  };

  updatePrice = async ({ id, trialDays, newPriceId }) => {
    return await this.service.put(`/stripe/price/${id}`, {
      trialDays,
      newPriceId,
    });
  };

  updateFreeSubscribeData = async (data) => {
    return await this.service.put(`/stripe/free-subscribe`, data);
  };
}

export default BillingAPI;
