import styles from './menu.module.scss'
import {Link, useNavigate} from "react-router-dom";
import {logoutUser} from "../../store/operations/profile-operations";
import {useDispatch} from "react-redux";
import {Button} from "../button/Button";

export const Menu = props => {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const logoutHandler = () => {
        dispatch(logoutUser())
    }

    return (
        <nav className={styles.menu}>
            <span className={styles.menu_logo} onClick={navigate.bind(null, '/dashboard')}>Formulaic</span>
            <Link className={styles.menu_nav_link} to={'/dashboard/users'}>Users</Link>
            <Link className={styles.menu_nav_link} to={'/dashboard/workouts'}>Workouts</Link>
            <Link className={styles.menu_nav_link} to={'/dashboard/challenges'}>Challenges</Link>
            <Link className={styles.menu_nav_link} to={'/dashboard/instructors'}>Instructors</Link>
            <Link className={styles.menu_nav_link} to={'/dashboard/equipments'}>Equipments</Link>
            <Link className={styles.menu_nav_link} to={'/dashboard/settings'}>Settings</Link>
            <Link className={styles.menu_nav_link} to={'/dashboard/billing'}>Billing</Link>
            <Link className={styles.menu_nav_link} to={'/dashboard/logs'}>Logs</Link>
            <Link className={styles.menu_nav_link} to={'/dashboard/api-doc'}>API Doc</Link>
            <Button onClick={logoutHandler} title={'Log Out'}/>
        </nav>
    )
}
