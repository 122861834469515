import { BaseAPI } from "./BaseAPI";

class WorkoutsAPI extends BaseAPI {
  constructor() {
    super("workout");
  }

  createWorkout = async (workoutData) => {
    return await this.service.post("", workoutData);
  };

  updateWorkout = async (workoutData, id) => {
    return await this.service.put(`/${id}`, workoutData);
  };

  getById = async (id) => {
    return await this.service.get(`/${id}`);
  };

  updateWorkoutVideo = async (workoutVideoData, id) => {
    return await this.service.put(`/${id}/video`, workoutVideoData);
  };

  deleteWorkout = async (id) => {
    return await this.service.delete(`/${id}`);
  };

  getAllWorkouts = async ({
    bodyPart,
    difficult,
    offset = 0,
    limit = 20,
    text,
  }) => {
    return await this.service.get("/all", {
      params: {
        bodyPart,
        difficult,
        offset,
        limit,
        text,
      },
    });
  };

  addCustomThumbnail = async (customThumbnail, id) => {
    // const jsonToFormData = (data) => {
    //   const formData = new FormData();
    //   for (let key in data) {
    //     if (data[key] !== null && data[key] !== undefined) {
    //       if (Array.isArray(data[key])) {
    //         data[key].forEach((element) => {
    //           formData.append(key, element);
    //         });
    //       } else {
    //         formData.append(key, data[key]);
    //       }
    //     }
    //   }
    //   return formData;
    // };
    const jsonToFormData=(data)=>{
        const formData=new FormData();
        formData.append("customThumbnail",data)
        return formData;
    }
    console.log(Object.fromEntries(jsonToFormData(customThumbnail?.customThumbnail),"THUM"))
    return await this.service.put(
      `/${id}/set-custom-thumbnail`,
      jsonToFormData(customThumbnail?.customThumbnail)
    );
  };

  resetAverageHR = async (id, partIndex) => {
    return await this.service.put(`/${id}/parts/${partIndex}/reset-average-hr`);
  }

  resetWorkoutDifficulty = async (id) => {
    return await this.service.put(`/${id}/reset-difficulty`);
  }
}

export default WorkoutsAPI;
