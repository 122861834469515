import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { Button } from "../../../components/button/Button";
import { useAlert } from "../../../hooks/useAlert";
import WorkoutsAPI from "../../../api/WorkoutsAPI";

import styles from "./workout-page.module.scss";

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const WorkoutPage = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);

  const [currentPosition, setCurrentPosition] = useState(0);
  const [workoutData, setWorkoutData] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [thumbnailUpdated, setThumbnailUpdated] = useState(false);
  const [successAlert, errorAlert] = useAlert();

  const deleteHandler = () => {
    new WorkoutsAPI()
      .deleteWorkout(id)
      .then((res) => {
        successAlert("Workout has been deleted");
        navigate("/dashboard/workouts");
      })
      .catch(errorAlert);
  };

  const difficultyResetHandler = () => {
    new WorkoutsAPI()
      .resetWorkoutDifficulty(id)
      .then((res) => {
        successAlert("Workout's has been reset");
      })
      .catch(errorAlert);
  };

  useEffect(() => {
    if (ref?.current) {
      const player = new Vimeo.Player(ref.current); // eslint-disable-line
      player.setCurrentTime(currentPosition);
    }
  }, [currentPosition]);

  useEffect(() => {
    new WorkoutsAPI()
      .getById(id)
      .then((res) => {
        setWorkoutData(res.data);
      })
      .catch((error) => {
        errorAlert(error);
        navigate("/dashboard/workouts", { replace: true });
      });
  }, [thumbnailUpdated]);
  const videoId = workoutData?.video?.link.split("/")[3];
  const thumbnailHandler = (e) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      setThumbnailImage(reader.result);
    };
    reader.readAsDataURL(e?.target?.files[0]);
    const videoFile = new File([e?.target?.files[0]], "baseWorkoutData.title");
    setVideoThumbnail(videoFile);
  };
  const saveChangesHandler = (e) => {
    new WorkoutsAPI()
      .addCustomThumbnail(
        {
          customThumbnail: videoThumbnail,
        },
        id
      )
      .then((response) => {
        successAlert("Thumbnail successfully updated");
        setVideoThumbnail(null);
        setThumbnailImage(null);
        setThumbnailUpdated(!thumbnailUpdated);
      });
    // return (async () => {
    //   await axios
    //     .get(
    //       `https://api.vimeo.com/videos/${videoId}?fields=metadata.connections.pictures.uri`,
    //       {
    //         headers: {
    //           Authorization: `bearer 992b4f8d8cd8ab09cbd054244acc7fd5`,
    //           Accept: "application/vnd.vimeo.*+json;version=3.4",
    //         },
    //       }
    //     )
    //     .then(async (response) => {
    //       const picture_uri =
    //         response?.data?.metadata?.connections?.pictures?.uri;
    //       await axios
    //         .post(`https://api.vimeo.com${picture_uri}`, null, {
    //           headers: {
    //             Authorization: `bearer 992b4f8d8cd8ab09cbd054244acc7fd5`,
    //             Accept: "application/vnd.vimeo.*+json;version=3.4",
    //           },
    //         })
    //         .then(async (response) => {
    //           const thumbnailUrl = response?.data?.link;
    //           const thumbnailUri = response?.data?.uri;
    //           await axios
    //             .put(thumbnailUrl, videoThumbnail, {
    //               headers: {
    //                 "Content-Type": "image/jpg, image/png, or image/gif",
    //                 Accept: "application/vnd.vimeo.*+json;version=3.4",
    //               },
    //             })
    //             .then(async () => {
    //               await axios
    //                 .patch(
    //                   `https://api.vimeo.com${thumbnailUri}`,
    //                   { active: true },
    //                   {
    //                     headers: {
    //                       Authorization: `bearer 992b4f8d8cd8ab09cbd054244acc7fd5`,
    //                       "Content-Type": "application/json",
    //                       Accept: "application/vnd.vimeo.*+json;version=3.4",
    //                     },
    //                   }
    //                 )
    //                 .then((response) => {
    //                   successAlert("Thumbnail successfully updated");
    //                   setVideoThumbnail(null);
    //                   setThumbnailImage(null);
    //                   setThumbnailUpdated(!thumbnailUpdated);
    //                 });
    //             });
    //         });
    //     });
    // })();
  };
  const resetTargetHRHandler = (partIndex) => () => {
    new WorkoutsAPI().resetAverageHR(id, partIndex)
      .then((res) => {
        setWorkoutData(res.data);
      })
      .catch((error) => {
        errorAlert(error);
      });
  }

  console.log(workoutData?.video?.customThumbnail, "IMAGE");
  return (
    <>
      <p className={styles.workout__paragraph}>
        <span>Title: </span>
        {workoutData?.title}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Instructor: </span>
        {workoutData?.instructor}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Style: </span>
        {workoutData?.style}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Body Part: </span>
        {Array.isArray(workoutData?.bodyPart)
          ? workoutData?.bodyPart.join(",")
          : workoutData?.bodyPart}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Calories: </span>
        {workoutData?.calory}
      </p>
      <p className={cn(styles.workout__paragraph, styles.workout__difficultyParagraph)}>
        <span>Difficulty: </span>
        {workoutData?.difficulty}
        <Button onClick={difficultyResetHandler} className={styles.workout__difficultyResetButton} isGrey={true}>Reset</Button>
      </p>
      <p className={styles.workout__paragraph}>
        <span>HR: </span>
        {workoutData?.hr}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Equipment: </span>
        {workoutData?.equipments?.join(" ")}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Prioritize when a watch is connected: </span>
        {capitalize(workoutData?.prioritizeWhenWatchConnected.toString() || "")}
      </p>
      {workoutData && (
        <div
          className={styles.workout__player}
          key={thumbnailUpdated.toString()}
        >
          <iframe
            ref={ref}
            src={`https://player.vimeo.com/video/${workoutData?.video?.link?.match(
              /[0-9]+/
            )}`}
            frameBorder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
          />
          <div className={styles.hasProfileUploadWrapper}>
            <div className={styles.profilePhotoWrapper}>
              {(thumbnailImage !== null ||
                workoutData?.video?.customThumbnail) && (
                <div className={styles.hasImage}>
                  <span className={styles.uploadImg}>
                    <img
                      src={
                        thumbnailImage !== null
                          ? thumbnailImage
                          : `http://13.233.62.209:4000/static/images/workoutThumbnail/${workoutData?.video?.customThumbnail}`
                      }
                      alt={"Thumbnail_preview"}
                    />
                  </span>
                </div>
              )}
              <div>
                <div className={styles.hasUploadButton}>
                  <label htmlFor="uploadImage">Update Thumbnail</label>
                  <input
                    type="file"
                    id={`uploadImage`}
                    accept=".png, .jpg"
                    onChange={thumbnailHandler}
                  />
                  {/* {thumbnailImage && (
                    <p
                      onClick={() => (
                        setThumbnailImage(null), setVideoThumbnail(null)
                      )}
                      style={{ cursor: "pointer" }}
                    >
                      Remove Thumbnail
                    </p>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          {workoutData?.video?.parts?.map(({ name, nodes, targetHR, averageHR }, partIndex) => {
            return (
              <table className={styles.workout__player__parts} key={name}>
                <thead>
                  <tr>
                    <th>Difficulty</th>
                    <th>Time Start</th>
                    <th>Time End</th>
                    <th>Target HR</th>
                    <th>Average HR</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {nodes.map(({ difficult, timeStart, timeEnd }) => {
                    return (
                      <tr
                        className={styles.workout__player__parts__node}
                        key={timeStart + timeEnd}
                        onClick={setCurrentPosition.bind(null, timeStart)}
                      >
                        <td>{difficult}</td>
                        <td width={"20%"}>{timeStart}</td>
                        <td width={"20%"}>{timeEnd}</td>
                        <td width={"20%"}>{targetHR}</td>
                        <td width={"20%"}>{averageHR}</td>
                        <td width={"20%"}><Button isGrey={true} onClick={resetTargetHRHandler(partIndex)}>Reset target HR</Button></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          })}
        </div>
      )}
      <div className={styles.buttonsWrapper}>
        <Button onClick={deleteHandler} title={"Delete workout"} />
        {videoThumbnail !== null && (
          <Button onClick={saveChangesHandler} type="button">
            Save Changes
          </Button>
        )}
      </div>
    </>
  );
};
